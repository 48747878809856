import { Anchor, Button, Col, Div, Icon, Input, Notification, Row, Text } from "atomize"
import { Link } from "gatsby"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import addToMailchimp from "gatsby-plugin-mailchimp"

import WaveFooter from "../images/assets/w-home-footer.svg" //rossa
import Facebook from "../images/assets/facebook.svg"
import Instagram from "../images/assets/instagram.svg"
import Linkedin from "../images/assets/linkedin.svg"
import PrimaryButton from "./buttons/PrimaryButton"

let config = require("../settings/config")

const url = config.url

const Footer = () => {
    const [email, setEmail] = useState("")
    const [successToast, setShowSuccess] = useState(false)
    const [dangerToast, setShowDanger] = useState(false)

    const handleMailChange = e => {
        setEmail(e.target.value)
    }

    const handleSubmission = e => {
        e.preventDefault()
        addToMailchimp(
            email,
            {},
            "https://squiseat.us20.list-manage.com/subscribe/post?u=5b1c31f5e6a30c8a7f20fe19a&amp;id=f7fd80c015"
        )
            .then(data => {
                console.log(data)
                setShowSuccess(true)
            })
            .catch(() => {
                setShowDanger(true)
            })
        /*const form = e.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader("Accept", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return
            if (xhr.status === 200) {
                form.reset()
                setShowSuccess(true)
            } else {
                setShowDanger(true)
            }
        }
        xhr.send(data)*/
    }

    return (
        <footer>
            <Div bg="primary" pos="relative" overflow="hidden">
                <WaveFooter className="wave" style={{ position: "relative", zIndex: "1", marginTop: "-0.25rem" }} />
                <Div
                    pos="relative"
                    zIndex="3"
                    p={{
                        x: { xs: "1.75rem", md: "3rem", lg: "5rem", xl: "12rem" },
                        b: "4rem",
                        t: { xs: "3rem", md: "7rem", lg: "9rem", xl: "8rem" },
                    }}
                    m={{ t: { xs: "-5rem", sm: "-8rem", md: "-10rem", lg: "-15rem", xl: "-18rem" } }}
                >
                    <Link to={url.home} title="Home Page | Squiseat">
                        <StaticImage
                            src="../images/squiseat-logo-negativo.png"
                            alt="Logo di Squiseat negativo"
                            placeholder="blurred"
                            layout="fixed"
                            width={80}
                        />
                    </Link>
                    <Row m="0">
                        <Col size={{ xs: "12", md: "6", lg: "4" }}>
                            <Text
                                p={{ t: "0.75rem" }}
                                textSize={{ xs: "subheader", md: "title" }}
                                textColor="white"
                                textWeight="bold"
                            >
                                Vieni a scoprirci su
                            </Text>
                            <Row p={{ t: "1rem", r: { xs: "1rem", sm: "2rem", lg: "4rem" } }}>
                                <Col>
                                    <Anchor
                                        href="https://www.facebook.com/squiseat"
                                        title="Facebook | Squiseat"
                                        target="_blank"
                                    >
                                        <Facebook style={{ width: "3rem" }} />
                                    </Anchor>
                                </Col>
                                <Col>
                                    <Anchor
                                        href="https://www.instagram.com/squiseat_/"
                                        title="Instagram | Squiseat"
                                        target="_blank"
                                    >
                                        <Instagram style={{ width: "3rem" }} />
                                    </Anchor>
                                </Col>
                                <Col>
                                    <Anchor
                                        href="https://www.linkedin.com/company/19178480/"
                                        title="Linkedin | Squiseat"
                                        target="_blank"
                                    >
                                        <Linkedin style={{ width: "3rem" }} />
                                    </Anchor>
                                </Col>
                            </Row>
                            <form onSubmit={handleSubmission}>
                                <Text
                                    p={{ t: "0.75rem" }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                    textWeight="bold"
                                >
                                    Iscriviti alla newsletter
                                </Text>
                                <Input
                                    type="text"
                                    name="email"
                                    value={email}
                                    style={{ autocomplete: "off" }}
                                    autoComplete="off"
                                    onChange={handleMailChange}
                                    placeholder={"Inserisci la tua mail"}
                                    maxW="20rem"
                                    rounded="20px"
                                    border="2px solid"
                                    borderColor="gray800"
                                    focusBorderColor="gunmetal"
                                    h="3.5rem"
                                    hovershadow="4"
                                />
                                <PrimaryButton
                                    title="Conferma la mail"
                                    type="submit"
                                    m={{ t: "0.5rem" }}
                                    p={{
                                        x: {
                                            xs: "1rem",
                                            lg: "1.5rem",
                                        },
                                    }}
                                    bg="white"
                                    rounded="circle"
                                    text={
                                        <Text textColor="primary" textSize="subheader">
                                            Iscrivimi
                                        </Text>
                                    }
                                />
                            </form>
                        </Col>

                        <Col size={{ xs: "12", md: "6", lg: "4" }} p={{ x: "8px", t: { xs: "2rem" } }}>
                            <Text
                                m={{ b: { xs: "0.5rem", md: "1rem" } }}
                                textSize={{ xs: "subheader", md: "title" }}
                                textColor="white"
                                textWeight="bold"
                            >
                                MAPPA DEL SITO
                            </Text>
                            <Link to={url.home} title="Home Page | Squiseat">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    Home
                                </Text>
                            </Link>
                            <Link to={url.how} title="Come funziona | Squiseat">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    Come funziona
                                </Text>
                            </Link>
                            <Link to={url.business} title="Hai un'attività? Collabora con noi | Squiseat">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    Hai un'attività?
                                </Text>
                            </Link>
                            <Link to={url.why} title="Perché Squiseat | Squiseat">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    Perché Squiseat
                                </Text>
                            </Link>
                            <Link to={url.faq} title="Domande frequenti | Squiseat">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    Domande frequenti
                                </Text>
                            </Link>
                        </Col>

                        <Col
                            size={{ xs: "12", lg: "4" }}
                            textAlign={{ xs: "center", md: "left" }}
                            p={{ x: "8px", t: { xs: "2rem" } }}
                        >
                            <Text
                                p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                textSize={{ xs: "subheader", md: "title" }}
                                textColor="white"
                            >
                                P.IVA 03825131208
                            </Text>
                            <Text
                                p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                textSize={{ xs: "subheader", md: "title" }}
                                textColor="white"
                            >
                                Tel: +39 338 96 57 285
                            </Text>
                            <Anchor title="Inviaci una mail" href="mailto:info@squiseat.it">
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "subheader", md: "title" }}
                                    textColor="white"
                                >
                                    e-mail: info@squiseat.it
                                </Text>
                            </Anchor>
                            <Anchor
                                title="Termini e condizioni | Squiseat"
                                href="https://www.iubenda.com/termini-e-condizioni/89661145"
                                target="_blank"
                            >
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "body", md: "subheader" }}
                                    textColor="white"
                                    textWeight="bold"
                                >
                                    Termini e condizioni
                                </Text>
                            </Anchor>
                            <Link title="Note legali | Squiseat" to={url.legalNotice}>
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "body", md: "subheader" }}
                                    textColor="white"
                                    textWeight="bold"
                                >
                                    Note legali
                                </Text>
                            </Link>
                            <Anchor
                                title="Privacy Policy | Squiseat"
                                href="https://www.iubenda.com/privacy-policy/89661145"
                                target="_blank"
                            >
                                <Text
                                    p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                                    textSize={{ xs: "body", md: "subheader" }}
                                    textColor="white"
                                    textWeight="bold"
                                >
                                    Privacy Policy
                                </Text>
                            </Anchor>
                        </Col>
                    </Row>
                    <Text p={{ t: "0.75rem" }} textAlign="center" textSize="tiny" textColor="white">
                        Copyright {new Date().getFullYear()} Squiseat® Srl
                    </Text>
                </Div>
            </Div>

            <Notification
                bg="success100"
                textColor="success800"
                isOpen={successToast}
                onClose={() => setShowSuccess(false)}
                prefix={<Icon name="Success" color="success800" size="18px" m={{ r: "0.5rem" }} />}
            >
                Invio con successo!
            </Notification>

            <Notification
                bg="danger100"
                textColor="danger800"
                isOpen={dangerToast}
                onClose={() => setShowDanger(false)}
                prefix={<Icon name="CloseSolid" color="danger800" size="18px" m={{ r: "0.5rem" }} />}
            >
                Errore, ricontrolla la mail!
            </Notification>
        </footer>
    )
}

export default Footer
