import PropTypes from "prop-types"
import React, { memo } from "react"
import { Button, Div } from "atomize"
import { theme } from "../../settings/config"
import { motion } from "framer-motion"

PrimaryButton.propTypes = {
    action: PropTypes.func,
    bg: PropTypes.string,
    d: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.any,
    isLoading: PropTypes.bool,
    justify: PropTypes.string,
    m: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            x: PropTypes.string,
        }),
    ]),
    p: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            x: PropTypes.string,
        }),
    ]),
    pos: PropTypes.string,
    right: PropTypes.any,
    rounded: PropTypes.string,
    shadow: PropTypes.string,
    style: PropTypes.any,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textColor: PropTypes.string,
    textSize: PropTypes.string,
    x: PropTypes.string,
    zIndex: PropTypes.string,
}

PrimaryButton.defaultProps = {
    d: "flex",
    pos: "unset",
    justify: "unset",
    m: "auto",
    p: { x: "1rem" },
    bg: "transparent",
    textSize: "body",
    textColor: theme.colors.white,
    action: null,
    disabled: false,
    isLoading: false,
    zIndex: "unset",
    shadow: "",
    rounded: "20px",
}

function PrimaryButton({
    d,
    pos,
    right,
    justify,
    m,
    p,
    bg,
    textSize,
    textColor,
    action,
    text,
    disabled,
    isLoading,
    zIndex,
    shadow,
    id,
    style,
    rounded,
}) {
    return (
        <Div pos={pos} zIndex={zIndex} right={right}>
            <motion.div whileTap={{ scale: 0.95 }}>
                <Button
                    d={d}
                    justify={justify}
                    shadow={shadow}
                    m={m}
                    p={p}
                    h="3.5rem"
                    isLoading={isLoading}
                    bg={bg}
                    disabled={disabled}
                    w="fit-content"
                    rounded={rounded}
                    textSize={textSize}
                    textColor={textColor}
                    onClick={action}
                    style={{ ...style, whiteSpace: "nowrap" }}
                    id={id}
                >
                    {text}
                </Button>
            </motion.div>
        </Div>
    )
}

export default memo(PrimaryButton)
